import React, { Fragment } from 'react'
import styled from '@emotion/styled'
import Img from 'gatsby-image'
import { mq, util, colors, typography  } from 'src/styles'
import ContentfulRichText from 'src/components/ContentfulRichText'

const SvgWrap = styled.div`
	display: inline-block;
	vertical-align: top;
	svg {
		display: block;
		width: 100%;
		height: 100%;
	}
`

const StyledImage = styled(Img)`
	${ ({ small, medium }) => `
		> div {
			${ medium ? `
				${ mq.largeAndBelow } {
					padding-bottom: ${ 100.0 / medium.aspectRatio }% !important;
				}
			` : `` }
			${ small ? `
				${ mq.mediumAndBelow } {
					padding-bottom: ${ 100.0 / small.aspectRatio }% !important;
				}
			` : `` }
		}
	` }
	img {
		transition: opacity 1s ease-in-out !important;
	}
`

const StyledImageCaption = styled.p`
	margin-top: 25px;
	margin-bottom: 0;
	font-size: 14px;
	font-family: ${typography.secondaryFont};
	line-height: 18px;
	text-align: center;
	${ ({pageType}) => pageType === 'home' ? `

	` : ``}
`

const StyledImageCredit = styled.p`
	margin-top: 10px;
	margin-bottom: 0;
	font-size: 12px;
	font-family: ${typography.secondaryFont};
	line-height: 18px;
	text-align: center;
	${ ({ pageType }) => pageType === 'home' || pageType === 'challenges' ? `
		color: ${colors.white};
		font-family: ${typography.primaryFont};
		position: absolute;
		right: 5px;
		bottom: 5px;
  ` : `` }
`

const StyledRichText = styled.div`
	${ ({ type }) => type === 'caption' ? `
		margin-top: 25px;
  ` : `
  	margin-top: 10px;
  ` }
	margin-bottom: 0;
	p {
		${ ({ type }) => type === 'caption' ? `
		font-size: 14px;
  ` : `
  	font-size: 12px;
  ` }
		
		font-family: ${typography.secondaryFont};
		line-height: 18px;
		text-align: center;
	}
`

const ResponsiveImage = ({ image, small, medium, large, className, loading, customSizes }) => {
	if (small || medium || large || image) {
		let source = [];
		let orientation = ''

		if (image) {
			let imageMq = `(min-width: 1px)`
			if (customSizes) {
				image.fluid.sizes = customSizes
			}
			if (medium) {
				imageMq = `(min-width: ${ mq.largeBreakpoint + 1 }px)`
			}

			if (small) {
				imageMq = `(min-width: ${ mq.mediumBreakpoint + 1 }px)`
			}
			source.push({
				...image.fluid,
				media: imageMq
			})

			if ( image.fluid.aspectRatio > 1 ) {
				orientation = 'landscape'
			} else {
				orientation = 'portrait'
			}
		}

		if (medium) {
			if (customSizes) {
				medium.fluid.sizes = customSizes
			}
			source.push({
				...medium.fluid,
				media: `(min-width: ${ mq.mediumBreakpoint + 1 }px)`
			})
		}

		if (small) {
			if (customSizes) {
				small.fluid.sizes = customSizes
			}
			source.push({
				...small.fluid,
				media: `(min-width: 1px)`
			})
		}

		if (!small && !medium) {
			source = image.fluid
		}
		return (
			<StyledImage
				className={className + ' ' + orientation}
				fluid={source}
				small={small}
				medium={medium}
				placeholderStyle={{ display: 'none' }}
				durationFadeIn={1000}
				loading={loading}
				customSizes={customSizes}
				orientation={orientation != '' ? orientation : false }
			/>
		)
	} else {
		return false
	}
}

const Image = ({ image, small, medium, className, sizes, loading, maxWidth, style, imageOverlay, imageCaption, imageCredit, showCaptions, pageType, imageCaptionStyled, imageCreditStyled, zoomable }) => { 
	// console.log('Render Image : :', small)

	const renderImageCaption = (showCaptions, pageType, imageCaption, imageCaptionStyled) => {
		if ( showCaptions === true ){
	    if( imageCaptionStyled) {
	    	return (
	    		<StyledRichText type='caption'>
	    		<ContentfulRichText richText={imageCaptionStyled.json}/>
	    		</StyledRichText>
	    	)
	    } else if (imageCaption) {
	    	return (
	    		<StyledImageCaption pageType={pageType}>{imageCaption.imageCaption}</StyledImageCaption>
    		)
	    } else {
	    	return false;
	    }
		}
  }

  const renderImageCredit = (showCaptions, pageType, imageCredit, imageCreditStyled) => {
		if ( showCaptions === true ){
	    if( imageCreditStyled) {
	    	return (
	    		<StyledRichText type='credit'>
	    		<ContentfulRichText richText={imageCreditStyled.json}/>
	    		</StyledRichText>
	    	)
	    } else if (imageCredit) {
	    	if (pageType === 'home' || pageType === 'challenges' ){
	    		return false;
	    	} else {
		    	return (
		    		<StyledImageCredit>{imageCredit}</StyledImageCredit>
	    		)
	    	}
	    } else {
	    	return false;
	    }
		}
  }

  const renderAbsoluteImageCredit = (showCaptions, pageType, imageCredit) => {
  	if ( showCaptions === true ){
	    if ( pageType === 'home' || pageType === 'challenges' ){
	    	if ( imageCredit ) {
		    	return (
		    		<StyledImageCredit 
							pageType={pageType}
						>
							{imageCredit}
						</StyledImageCredit>
	    		)
	    	} else {
	    		return false;
	    	}
	    } else {
	    	return false;
	    }
		} else {
			return false;
		}
  }

	return(
	<div style={{
		width: '100%',
		maxWidth: maxWidth || '100%',
		display: 'inline-block',
		verticalAlign: 'top',
		position: 'relative'
	}}>
		{image && image.svgContent ? (
			<SvgWrap className={className} dangerouslySetInnerHTML={{ __html: image.svgContent }}/>
		) : (
		<div style={{
			width: '100%',
			maxWidth: maxWidth || '100%',
			display: 'inline-block',
			verticalAlign: 'top',
			position: 'relative'
		}}>
			<ResponsiveImage
				image={image}
				small={small}
				medium={medium}
				className={className}
				customSizes={sizes}
				loading={loading}
			/>
			
			{imageOverlay && (
				<div className="home-image-overlay">
	      </div>
			)}

			{renderAbsoluteImageCredit(showCaptions, pageType, imageCredit)}
		</div>
		)}
		{renderImageCaption(showCaptions, pageType, imageCaption, imageCaptionStyled)}
		{renderImageCredit(showCaptions, pageType, imageCredit, imageCreditStyled)}
	</div>
)}

Image.defaultProps = {
	loading: 'lazy',
	sizes: false,
	style: 'default'
}

export {
	ResponsiveImage,
	Image as default
}
