import React, { Component } from 'react'
import styled from '@emotion/styled'
import { useStaticQuery, graphql } from 'gatsby'

import Link from 'src/components/Link'
import Grid from 'src/components/Grid'
import { Logomark } from 'src/components/Logo'
import MailchimpSignup from 'src/components/MailchimpSignup'
import Section from 'src/components/Section'
import ThemeSelector from 'src/components/ThemeSelector'
import ConditionalRender from 'src/components/ConditionalRender'
import ContentfulRichText from 'src/components/ContentfulRichText'

import SaveTheTidalBasinLogo from 'src/assets/images/footer/SaveTheTidalBasinLogo.svg'
import FacebookLogo from 'src/assets/images/footer/facebook_w.svg'
import TwitterLogo from 'src/assets/images/footer/twitter_w.svg'
import InstagramLogo from 'src/assets/images/footer/instagram_w.svg'
import YoutubeLogo from 'src/assets/images/footer/youtube_w.svg'
// import Nthp from 'src/assets/images/footer/NTHP.png'
// import Tnm from 'src/assets/images/footer/TNM.png'


import { globals, typography, colors, animations, mediaQueries, util } from 'src/styles'

const Wrapper = styled(ThemeSelector)`
	position: relative;
	z-index: 2;
	width: 100%;
`

const FooterSection = styled(Section)`
	${ util.responsiveStyles('padding-top', 60, 60, 60, 26) }
	${ util.responsiveStyles('padding-bottom', 60, 60, 60, 26) }
`

const FooterLogo = styled(Logomark)`
	width: 30px;
	${ mediaQueries.mediumAndBelow } {
		width: 24px;
	}
`

const FooterBottom = styled(ThemeSelector)`
	position: relative;
	${ util.responsiveStyles('padding-top', 0, 0, 0, 26) }
	${ util.responsiveStyles('padding-bottom', 100, 100, 100, 26) }
`

const FooterLogoWrapper = styled.div`
	height: auto;
	display: inline-block;
	vertical-align: top;
	text-align: center;
	width: 100%;
	max-width: 150px;
	margin: 0 auto;
	svg {
		display: inline-block;
		vertical-align: top;
		width: 100%;
		height: auto;
		color: inherit;
		* {
			fill: currentcolor;
		}
	}
`

const SocialGrid = styled(Grid)`
`

const SocialLogoWrapper = styled.div`
	width: 100%;
	text-align: center;
	svg {
		max-width: 28px;
		margin: 0 auto;
	}

`

const LogoGroup = styled.div`
	text-align: left;
	h6 {
		font-family: ${typography.primaryFont};
		text-transform: none;
		letter-spacing: 0;
		margin-bottom: 27px;

	}
	${mediaQueries.smallAndBelow} {
		margin-bottom: 35px;

		h6 {
			font-size: 12px;
			margin-bottom: 10px;
			text-align: center;
		}
	}
`

const Copyright = styled.div`
	display: flex;
	align-items: center;
	p {
		margin: 0;
		max-width: none;
		padding-left: 16px;
		.mobile-hide {
			${ mediaQueries.mediumAndBelow } {
				display: none;
			}
		}
	}
`

const SiteCredit = styled.div`
	text-align: right;
	p {
		margin: 0;
		max-width: none;
		a {
			opacity: 0.6;
			&:hover {
				opacity: 1;
			}
		}
	}
`

const FooterSponsorsWrap = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: flex-start;
`

const FooterSponsorLogos = styled.div`
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: space-between;
	
	img {
		width: 100%;
		height: auto;
		&.amex{
			margin-left: -15px;
		}
		&:not(.nps) {
			max-width: 140px;
		}
		&.nps {
			max-width: 45px;
		}
		&.som {
			max-width: 110px;
		}
	}
	${mediaQueries.smallAndBelow} {
		display: block;
		img {
			display: block;
			padding: 15px 0;
			margin: 0 auto;
			&.amex{
				margin: 0 auto;
			}
			&:not(.nps) {
				max-width: 175px;
			}
			&.nps {
				max-width: 54px;
			}
			&.som {
				max-width: 115px;
			}
		}
	}
`

const SocialWrapper = styled.div`
	position: absolute; 
	${ util.responsiveStyles('top', 58, 58, 58, 25) };
	${ util.responsiveStyles('right', 58, 58, 58, 25) };
	${ util.responsiveStyles('width', 105, 105, 105, 65) };
	z-index: 3;
`

const Footer = () => {
	const { amex, nps, nthp, tnm, som } = useStaticQuery(
		graphql`
			query {
				amex: file(relativePath:{eq: "images/footer/AMEX.png"}) {
					publicURL
				}
				nps: file(relativePath: { eq: "images/footer/NPS.png" }) {
					publicURL
				}
				nthp: file(relativePath: { eq: "images/footer/NHTP_no tag.png" }) {
					publicURL
				}
				tnm: file(relativePath: { eq: "images/footer/TNM.png" }) {
					publicURL
				}
				som: file(relativePath: { eq: "images/footer/SOM.png" }) {
					publicURL
				}
			}
		`)
	const host = process.env.HOST || process.env.GATSBY_HOST
	return (
			<Wrapper setTheme="textColor">
				<FooterSection>
					<Grid
						small="1 [10] 1"
						medium="5 [2] 5"
						large="5 [2] 5"
						vAlign="center"
					>
						<FooterLogoWrapper>
							<SaveTheTidalBasinLogo />
						</FooterLogoWrapper>
					</Grid>
					
				</FooterSection>

				<FooterBottom setTheme="textColor">
					<Grid
						small="1 [10] 1"
						medium="1 [2] 1 [5] 2 [2] 1"
						large="1 [3] 1 [8] 3 [2] 1"
						vAlign="top"
					>
						<LogoGroup>
							<h6>Presented by</h6>
							<FooterSponsorLogos>
								<a href="https://www.americanexpress.com/" target="_blank">
								<img src={host + amex.publicURL} alt="Logo" className="amex"/>
								</a>
							</FooterSponsorLogos>
						</LogoGroup>

						<LogoGroup>
							<h6>Partners</h6>
							<FooterSponsorLogos>
									<a href="https://savingplaces.org/" target="_blank">
									<img src={host + nthp.publicURL} alt="Logo" className="nthp" />
									</a>

									<a href="https://nationalmall.org/" target="_blank">
									<img src={host + tnm.publicURL} alt="Logo" className="tnm" />
									</a>

									<a href="https://www.nps.gov/" target="_blank">
									<img src={host + nps.publicURL} alt="Logo" className="nps" />
									</a>

							</FooterSponsorLogos>
						</LogoGroup>

						<LogoGroup>
								<h6>Civic Partner</h6>
								<FooterSponsorLogos>
									<a href="https://www.som.com/" target="_blank">
									<img src={host + som.publicURL} alt="Logo" className="som" />
									</a>
								</FooterSponsorLogos>
						</LogoGroup>
					</Grid>
				</FooterBottom>

				<SocialWrapper>
					<SocialGrid
						small="[6] 5 [6]"
						medium="[6] 1 [6]"
						large="[6] 1 [6]"
						vAlign="center"
					>

						<Link
							to={'https://twitter.com/hashtag/savethetidalbasin'}
							external={true}
							target="_blank"
						>
							<SocialLogoWrapper>
								<TwitterLogo />
							</SocialLogoWrapper>
						</Link>
						<Link
							to={'https://www.instagram.com/explore/tags/savethetidalbasin/'}
							external={true}
							target="_blank"
						>
							<SocialLogoWrapper>
								<InstagramLogo />
							</SocialLogoWrapper>
						</Link>
						
					</SocialGrid>
				</SocialWrapper>
			</Wrapper>
		)

}
export default Footer
